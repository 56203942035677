import React from 'react'
import { graphql } from 'gatsby'
import ContentFold from '../components/page-content/content-fold'
import Layout from '../components/layout'
import Footer from '../components/landing-page/footer'

const Template = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <ContentFold title={frontmatter.title} isCentered>
        <div className="content" dangerouslySetInnerHTML={{ __html: html }} />
      </ContentFold>
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`

export default Template
